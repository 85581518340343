/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  FC,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import clsx from "clsx";
import {
  defaultMessages,
  defaultUserInfos,
  KTSVG,
  messageFromClient,
  MessageModel,
  toAbsoluteUrl,
  UserInfoModel,
} from "../../helpers";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as URLParse from "url-parse";
import { useParams } from "react-router-dom";
import { Dropdown1 } from "../content/dropdown/Dropdown1";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css"; // Import the emoji styles
import "quill-emoji/dist/quill-emoji.js";
// import {sound} from '../../../../public/media/public/media/message_tune.mp3';
import { EventModal } from "./EventModal";
import { Dropdown } from "react-bootstrap";
import { TemplateMessageModal } from "./TemplateMessageModal";
import { LeadAssignModal } from "./LeadAssign";
import { useMediaQuery } from "react-responsive";
import { SidebarContext } from "../../layout/components/aside/SidebarContext";
import * as conv from "../../../app/modules/auth/redux/ConvRedux";
// import SearchableDropdown from "./SearchableDropdown";

type Props = {
  isDrawer?: boolean;
  customer_profile?: any;
  onContactClick?: any;
  isMobile?: any;
  socket?: any;
  incomingMessage?: any;
};

const bufferMessages = defaultMessages;

const formatMessageText = (text: string) => {
  text = text.replace(/<pic>(.*?)<\/pic>/g, (match, url) => {
    return `<img src="${url}" alt="Picture" class="w-100 h-200px rounded-4 mb-4"/>`;
  });

  // Replace URLs with anchor tags
  text = text.replace(
    /(?<!")\b(https?:\/\/[^\s"]+)(?!")\b/g,
    '<a href="$1" target="_blank">$1</a>'
  );

  // Replace emails with mailto links
  text = text.replace(
    /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g,
    '<a href="mailto:$&">$&</a>'
  );

  text = text.replace(
    /[\u{1D400}-\u{1D7FF}]/gu, // Bold mathematical alphanumeric block
    (match) => `<b>${match}</b>`
  );

  // Replace bold, italic, and strikethrough enclosed in *, _, and ~ respectively
  text = text.replace(/\*([^*]+)\*(?=\s|$)/g, "<b>$1</b>");
  text = text.replace(/_([^_]+)_(?=\s|$)/g, "<i>$1</i>");
  text = text.replace(/~([^~]+)~(?=\s|$)/g, "<s>$1</s>");

  // Replace newline characters with <br> tags
  text = text.replace(/\n/g, "<br>");

  return text;
};

const ChatInner: FC<Props> = ({
  isDrawer = false,
  customer_profile,
  onContactClick,
  isMobile,
  socket,
  incomingMessage,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const MEDIA_URL = API_URL?.slice(0, -4);
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<any>();
  const [booking, setBooking] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [salesTeam, setSalesTeam] = useState<any>();
  const isTablet = useMediaQuery({ query: "(max-width: 840px)" });
  const dispatch = useDispatch();
  // const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  let lastDate = "";
  const user = useSelector((state: any) => state.auth.user);
  const { slug } = useParams();
  const GET_CONVERSATION_API = `${API_URL}/get-conversation/${
    slug ? slug : user.company.slug
  }/${customer_profile.conversation_id}/`;
  const GET_BOOKING_API = `${API_URL}/get-booking/${
    slug ? slug : user.company.slug
  }/${customer_profile.id}/`;
  const MARK_AS_READ_API = `${API_URL}/conversation-read/${customer_profile.conversation_id}/`;
  const chatBodyRef = useRef<any>(null);
  const bottomElementRef = useRef<any>(null);
  const [chatHeight, setChatHeight] = useState("100vh");
  const [quillToolbar, setQuillToolbar] = useState<any>({
    container: [
      ["bold", "italic", "underline", "strike"],
      // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ["emoji"],
      // ['clean'],
    ],
  });
  const [quillFormat, setQuillFormat] = useState<any>([
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "emoji",
  ]);
  const [quillModules, setQuillModules] = useState<any>({
    toolbar: quillToolbar,
    "emoji-toolbar": true, // Enable emoji toolbar
    "emoji-shortname": true,
  });
  const [showEventModal, setShowEventModal] = useState(false);
  const handleEventModalClose = (isFetch?: Boolean) => {
    if (isFetch) fetchData();
    setShowEventModal(false);
  };

  const { isSidebarOpen, toggleOpen, isDashboardVisible, toggleDashboard } =
    useContext<any>(SidebarContext);
  const handleEventModalShow = () => setShowEventModal(true);

  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const handleTemplateModalClose = () => setShowTemplateModal(false);
  const handleTemplateModalShow = () => setShowTemplateModal(true);
  const handleLeadModalClose = () => setIsOpen(false);
  const handleLeadModalShow = () => setIsOpen(true);

  const setLinkInMessage = (event: any) => {
    setMessage(
      message.concat(
        `<p>Download Itinerary from here: ${MEDIA_URL}/tours/${event}/pdf/?download=1</p>`
      )
    );
  };

  const fetchData = async () => {
    try {
      // Make a GET request to your API endpoint
      const response = await axios.get(GET_CONVERSATION_API);

      const markAsReadResponse = await axios.post(MARK_AS_READ_API, {});

      const booking = await axios.get(GET_BOOKING_API);
      setBooking(booking?.data?.data?.booking);

      // console.log("inside chat inner use effect", response.data, customer_profile, user)
      const api_messages = response.data.data.messages
        ? response.data.data.messages.reverse()
        : [];
      setMessages(api_messages);
    } catch (error) {
      // Handle errors
      console.log(error);
    }
  };

  const toolbarUpdate = () => {
    const element: any = document.querySelector(".ql-toolbar");
    if (element && element?.style?.display != "none") {
      element.style.display = "none";
    } else {
      element.style.display = "block";
    }
  };

  function formatContent(content: string): string {
    let pCount = 0; // Variable to track the occurrence of </p> tags
    const newContent = content.replace(
      /<\/?p>|<strong>|<\/strong>|<em>|<\/em>|<s>|<\/s>|<br>/g,
      (match: string) => {
        switch (match) {
          case "<p>":
            pCount++;
            return "";
          case "</p>":
            if (pCount > 0) {
              return "\n";
            }
            return "";
          case "<strong>":
            return "*";
          case "</strong>":
            return "*";
          case "<em>":
            return "_";
          case "</em>":
            return "_";
          case "<s>":
            return "~";
          case "</s>":
            return "~";
          case "<br>":
            return "\n";
          default:
            return match; // If no match found, return the original match
        }
      }
    );
    const contentWithoutSpan = newContent
      .replace(/<span[^>]*>/g, "")
      .replace(/<\/span>/g, "");

    return contentWithoutSpan;
  }

  const parsedURL = new URLParse(API_URL);

  // console.log("check income message", incomingMessage)

  const timeAgoOrDate = (timestamp: any) => {
    const messageTime = moment(timestamp);
    const currentTime = moment();
    const messageDate = messageTime.format("YYYY-MM-DD");
    const currentDate = currentTime.format("YYYY-MM-DD");

    if (messageDate === currentDate) {
      return "Today";
    } else if (
      messageTime.isSame(currentTime.clone().subtract(1, "days"), "day")
    ) {
      return "Yesterday";
    } else {
      return messageTime.format("DD/MM/YY");
    }
  };

  const formatTime = (timestamp: any) => {
    const messageTime = moment(timestamp);

    return messageTime.format("HH:mm");
  };

  useEffect(() => {
    fetchData();
  }, [customer_profile]);

  useEffect(() => {
    if (
      incomingMessage &&
      messages &&
      customer_profile.conversation_id === incomingMessage.conversation
    ) {
      setMessages((prevMessages: any) => {
        const messageIndex = prevMessages.findIndex(
          (msg: any) => msg.message_id === incomingMessage.message_id
        );
        console.log(
          "Inside use effect of chat inner for incoming message: ",
          incomingMessage
        );
        if (messageIndex !== -1) {
          // Update existing message
          const updatedMessages = [...prevMessages];
          updatedMessages[messageIndex] = incomingMessage;
          return updatedMessages;
        } else {
          // Insert at 0th index
          return [...prevMessages, incomingMessage];
        }
      });
    }
  }, [incomingMessage]);

  const handleResize = () => {
    const newHeight = window.innerHeight * 0.98; // Adjust the multiplier as needed
    setChatHeight(`${newHeight}px`);
  };

  useEffect(() => {
    // Initial height calculation
    toggleDashboard(false);
    handleResize();
    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Scroll when messages change

  const scrollToBottom = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    if (bottomElementRef?.current) {
      bottomElementRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessage = (conversation?: any) => {
    if (!isMessageEmpty()) {
      console.log(message);
      // const plainText = new DOMParser().parseFromString(message, 'text/html').body.textContent;
      const unicodeStr = formatContent(message);
      const newMessage = {
        user: 5,
        sent_by_customer: false,
        sender_user: null,
        message: unicodeStr,
        time: "Just now",
      };
      let data: any = {
        message: unicodeStr,
        sent_by: user.user_id,
        send_to: customer_profile.id,
        thread_id: customer_profile.conversation_id,
        param_list: [],
        template_id: "",
        is_template: false,
      };
      data = JSON.stringify(data);
      socket.send(data);
      // let temp = messages
      // temp.push(newMessage)
      // bufferMessages.push(newMessage);
      // console.log(bufferMessages);
      // setMessages(temp);
      toggleChatUpdateFlat(!chatUpdateFlag);
      setMessage("");
      setTimeout(() => {
        // bufferMessages.push(messageFromClient);
        // setMessages(() => bufferMessages);
        toggleChatUpdateFlat((flag) => !flag);
      }, 1000);
    }
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    console.log("inside enter press", e);

    if (e.key === "Enter" && e.shiftKey === false) {
      console.log("enterpressed");

      e.preventDefault();
      e.stopPropagation();
      sendMessage();
    }
  };
  const checkAndUpdateLastDate = (timestamp: any) => {
    if (lastDate != timestamp) {
      lastDate = timestamp;
      return true;
    }
    return false;
  };

  const isMessageEmpty = () => {
    const cleanedMessage = message.replace(/<[^>]+>/g, ""); // Remove HTML tags
    return cleanedMessage.trim() === "";
  };

  const formatDateTime = (datetimeString: any) => {
    const dateTime = moment(datetimeString);
    const timeFormat = dateTime.format("HH:mm");
    if (timeFormat == "00:00") {
      return dateTime.format("ddd DD-MMM");
    } else {
      return dateTime.format("ddd DD-MMM hh:mm A");
    }
  };

  function getTickIcon(msg: any) {
    if (msg.status === "failed") {
      return `<span class="failed_icon">
                <i class="bi bi-x-circle text-danger"></i>
                <span class="tooltip">${
                  msg.failure_reason || "Failed to send"
                }</span>
              </span>`;
    }
    switch (msg.status) {
      case "enqueued":
      case "submitted":
        return `<i class="bi bi-clock text-muted"></i>`;
      case "sent":
        return `<i class="bi bi-check"></i>`;
      case "delivered":
        return `<i class="bi bi-check-all"></i>`;
      case "read":
        return `<i class="bi bi-check-all text-primary"></i>`;
      default:
        return "";
    }
  }

  if (messages) {
    return (
      <>
        <EventModal
          show={showEventModal}
          handleClose={handleEventModalClose}
          company={slug ? slug : user.company.slug}
          customerProfile={customer_profile}
          setLinkInMessage={setLinkInMessage}
        />

        <TemplateMessageModal
          show={showTemplateModal}
          handleClose={handleTemplateModalClose}
          customerProfile={customer_profile}
          slug={slug ? slug : user.company.slug}
        />
        <LeadAssignModal
          show={isOpen}
          handleClose={handleLeadModalClose}
          slug={slug ? slug : user.company.slug}
          conversation_id={customer_profile.conversation_id}
          assigned_to={customer_profile.assigned_to?.peopleid}
          customer_profile={customer_profile.id}
        />

        <div
          className={`card pt-20 pt-lg-4 p-4 p-md-0 px-lg-8 py-lg-4 pt-md-0 pt-md-0 bg-transparent rounded-0 border-0 ${
            isTablet ? "pt-0" : "vh-100 p-4 px-8"
          } d-flex flex-column w-auto`}
          id="kt_chat_messenger"
          ref={chatBodyRef}
          style={{ height: chatHeight }}
        >
          <div
            className={`card-header flex-nowrap ${
              isTablet ? "rounded-0 shadow-none" : "rounded-2"
            } bg-white border-0 ${isMobile ? "px-4 rounded-2" : "px-5"}`}
            id="kt_chat_messenger_header"
          >
            <div className="card-title">
              {isMobile && (
                <button
                  className="btn btn-sm btn-icon btn-active-light-primary me-0"
                  type="button"
                  data-bs-toggle="tooltip"
                  title="Coming soon"
                  onClick={() => {
                    onContactClick(false);
                    dispatch(conv.convactions.SET_CONVERSATION_ID(null));
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr022.svg"
                    className="svg-icon-2 svg-icon-gray-500"
                  />
                </button>
              )}
              {/* <div className='symbol-group symbol-hover'></div> */}
              <div className="symbol symbol-45px symbol-circle me-2">
                <span
                  style={{ backgroundColor: "#f5f5f5" }}
                  className="symbol-label text-black fs-6 fw-bolder"
                >
                  {customer_profile?.fullname[0]}
                </span>
              </div>
              <div className="d-flex justify-content-center flex-column me-3">
                <a
                  href="#"
                  className="fs-5 text-truncate d-block fw-bolder text-gray-900 text-hover-primary me-1 lh-1 mb-1"
                  style={{ maxWidth: "29vw" }}
                >
                  {customer_profile?.fullname}
                  <span className="fs-7 fw-bold text-gray-400 ms-2">
                    {customer_profile?.mobile}
                  </span>
                </a>

                <div className="mb-0 lh-1">
                  {/* <span className='badge badge-success badge-circle w-10px h-10px me-1'></span> */}
                  <span
                    className="badge badge-sm badge-pill badge-secondary bg-hover-light"
                    style={{ fontSize: "0.65rem" }}
                    onClick={handleLeadModalShow}
                  >
                    Owner:{" "}
                    {customer_profile.assigned_to
                      ? customer_profile.assigned_to.username
                          .slice(0, 6)
                          .concat("..")
                      : "----"}
                  </span>
                  {booking?.start ? (
                    <>
                      <span
                        className="badge badge-sm badge-pill badge-light ms-2"
                        style={{ fontSize: "0.65rem" }}
                      >
                        ID: {booking?.id}
                      </span>
                      <span
                        className="badge badge-sm badge-pill badge-light ms-2"
                        style={{ fontSize: "0.65rem" }}
                      >
                        {booking?.eventname?.slice(0, 10).concat("...")}
                      </span>
                      <span
                        className="badge badge-sm badge-pill badge-light ms-2"
                        style={{ fontSize: "0.65rem" }}
                      >
                        {formatDateTime(booking?.start)}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="card-toolbar">
              <div>
                <a
                  href={`tel:+91${customer_profile?.mobile}`}
                  className="btn btn-sm btn-icon btn-active-light-primary border border-secondary rounded-circle"
                >
                  <i className="bi bi-telephone-fill"></i>
                </a>
                {/* <Dropdown1 /> */}
              </div>
            </div>
          </div>
          <div
            className="card-body pb-0 px-4 h-auto"
            style={{ overflowY: "auto" }}
            id={
              isDrawer
                ? "kt_drawer_chat_messenger_body"
                : "kt_chat_messenger_body"
            }
          >
            <div
              className={clsx("scroll-y px-5")}
              data-kt-element="messages"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies={
                isDrawer
                  ? "#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
                  : "#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
              }
              data-kt-scroll-wrappers={
                isDrawer
                  ? "#kt_drawer_chat_messenger_body"
                  : "#kt_content, #kt_chat_messenger_body"
              }
              data-kt-scroll-offset={isDrawer ? "0px" : "-2px"}
            >
              {messages.map((msg: any, index: any) => {
                const userInfo = customer_profile;
                const styles = {
                  position: "absolute",
                  right: "2%",
                  bottom: "5%",
                };
                const messageData = `<div>
                    <div class="mb-2">${formatMessageText(msg.message)}</div>
                    <div class="chat_data">
                      ${formatTime(msg.timestamp)}
                      <span class="tick_status">${getTickIcon(msg)}</span>
                    </div>
                  </div>
                `;
                const state = msg.sent_by_customer ? "white" : "";
                const textColor = msg.sent_by_customer
                  ? "text-dark"
                  : "text-dark";
                const borderCorner = msg.sent_by_customer
                  ? "remove-top-left-radius-0"
                  : "remove-top-right-radius-0";
                const templateAttr = {};
                const shadow = msg.sent_by_customer ? "shadow" : "shadow";
                // console.log("inside message lop",message, msg)
                if (msg.template) {
                  Object.defineProperty(templateAttr, "data-kt-element", {
                    value: `template-${msg.type}`,
                  });
                }
                const contentClass = `${
                  isDrawer ? "" : "d-flex"
                } justify-content-${
                  msg.sent_by_customer ? "start" : "end"
                } mb-10`;
                return (
                  <div key={`message${index}`}>
                    {checkAndUpdateLastDate(timeAgoOrDate(msg.timestamp)) && (
                      <div className="d-block mb-2 text-center sticky-top position-sticky">
                        <span className=" chat_date rounded-4">
                          {" "}
                          {timeAgoOrDate(msg.timestamp)}
                        </span>
                      </div>
                    )}

                    <div
                      className={clsx("d-flex", contentClass, "mb-10", {
                        "d-none": msg.template,
                      })}
                      {...templateAttr}
                    >
                      <div
                        className={clsx(
                          "d-flex flex-column align-items",
                          `align-items-${
                            msg.sent_by_customer ? "start" : "end"
                          }`
                        )}
                      >
                        <div className="d-flex flex-column align-items-center mb-2">
                          {msg.sent_by_customer ? (
                            <>
                              <div className="d-flex w-md-75 w-75 justify-content-start">
                                <a
                                  href="#"
                                  className="fs-5 text-truncate fw-bolder text-gray-900 text-hover-primary me-1"
                                >
                                  {userInfo.fullname}
                                </a>
                                {/* <span className="text-muted fs-7 mb-1">
                                  {formatTime(msg.timestamp)}
                                </span> */}
                              </div>
                              <div className="d-flex gap-4 align-items-baseline">
                                <div className="symbol symbol-45px symbol-circle">
                                  <span
                                    style={{ backgroundColor: "#dcdcdc" }}
                                    className="symbol-label text-black fs-6 fw-bolder border-1 border-black"
                                  >
                                    {userInfo.fullname[0]}
                                  </span>
                                </div>
                                <div
                                  className={clsx(
                                    "p-5 rounded-2",
                                    ` ${shadow} ${borderCorner} ${textColor}`,
                                    "bubble fw-bold mw-lg-400px mw-77 text-break",
                                    "text-start"
                                  )}
                                  style={{ backgroundColor: state }}
                                  data-kt-element="message-text"
                                  dangerouslySetInnerHTML={{
                                    __html: messageData,
                                  }}
                                ></div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex w-md-75 w-75 justify-content-end">
                                {/* <span className="text-muted fs-7 mb-1">
                                  {formatTime(msg.timestamp)}
                                </span> */}
                                <a
                                  href="#"
                                  className="fs-5 text-truncate fw-bolder text-gray-900 text-hover-primary ms-1"
                                >
                                  {msg.sender_user?.user
                                    ? msg.sender_user?.user
                                    : "You"}
                                </a>
                              </div>
                              <div className="d-flex flex-row-reverse gap-4 align-items-baseline">
                                <div className="symbol symbol-45px symbol-circle">
                                  <img
                                    alt="Pic"
                                    src={MEDIA_URL?.concat(
                                      user.company.cover_pic
                                    )}
                                  />
                                </div>
                                <div
                                  className={clsx(
                                    "p-5 rounded-2",
                                    ` ${shadow} ${borderCorner} ${textColor}`,
                                    `${
                                      msg.status == "failed"
                                        ? "failed_icon"
                                        : ""
                                    }`,
                                    "bubble-sender fw-bold mw-lg-400px mw-77 text-break",
                                    "text-start"
                                  )}
                                  // style={{ backgroundColor: state }}
                                  data-kt-element="message-text"
                                  // dangerouslySetInnerHTML={{
                                  //   __html: messageData,
                                  // }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: messageData,
                                    }}
                                  />
                                  {/* {msg.status === "failed" && (
                                    <div className="tooltip-container">
                                      <span className="tooltip">
                                        {msg.failure_reason || "Failed to send"}
                                      </span>
                                    </div>
                                  )} */}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className=""></div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div ref={bottomElementRef} />
            </div>
          </div>
          <div
            className={`card-footer pt-0 pb-2 px-4 border-0 ${
              isMobile ? "" : "rounded-5"
            }`}
            id={
              isDrawer
                ? "kt_drawer_chat_messenger_footer"
                : "kt_chat_messenger_footer"
            }
          >
            <div className="border border-secondary border-1 rounded-4">
              <div className="mh-25">
                <ReactQuill
                  className="form-control form-control-flush rounded-4 w-100 p-0"
                  theme="snow"
                  modules={quillModules}
                  formats={quillFormat}
                  value={message}
                  // onKeyDown={onEnterPress}
                  onChange={(
                    content: any,
                    delta: any,
                    source: any,
                    editor: any
                  ) => setMessage(content)}
                  // onKeyDown={onEnterPress}
                  placeholder="Type your message..."
                />
              </div>
              <div className="d-flex flex-stack rounded-bottom-4 bg-white">
                <div className="d-flex align-items-center me-2">
                  <button
                    className="btn btn-sm btn-icon btn-active-light-primary me-1"
                    type="button"
                    data-bs-toggle="tooltip"
                    title="Formatting"
                    onClick={toolbarUpdate}
                  >
                    <i className="bi bi-type"></i>
                  </button>
                  <Dropdown drop="up">
                    <Dropdown.Toggle
                      id="dropup-button-custom"
                      // onClick={() => setDropupOpen(!dropupOpen)}
                      className="btn btn-sm btn-icon btn-active-light-primary me-1 dropdown-toggle pulse"
                      variant="none"
                    >
                      <i className="bi bi-plus-circle-fill"></i>
                      <span className="pulse-ring"></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100 text-break">
                      <Dropdown.Item onClick={handleEventModalShow}>
                        Event Itineraries
                      </Dropdown.Item>
                      {/* <Dropdown.Item onClick={handleTemplateModalShow}>
                          Send Template message
                        </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <div className={`dropup ${dropupOpen ? 'dropup' : ''}`}>
                      <button 
                        className="btn btn-sm btn-icon btn-active-light-primary me-1 dropdown-toggle" 
                        type="button" 
                        id="itinerariesDropdown" 
                        onClick={() => setDropupOpen(!dropupOpen)}
                        aria-expanded={dropupOpen}
                        aria-haspopup="true"
                      >
                        <i className="bi bi-plus-circle-fill"></i>
                      </button>
                      <ul className={`dropdown-menu ${dropupOpen ? 'show' : ''}`} aria-labelledby="itinerariesDropdown">
                        <li><button className="dropdown-item" type="button" onClick={handleEventModalShow}>Show Event</button></li>
                        <li><button className="dropdown-item" type="button">Upload</button></li>
                      </ul>
                    </div> */}
                </div>
                <button
                  className="btn btn-sm btn-icon rounded-2 btn-active-light-primary btn-color-gray-900"
                  type="button"
                  data-kt-element="send"
                  onClick={sendMessage}
                  disabled={isMessageEmpty()}
                >
                  {/* <i className="bi bi-send"></i> */}
                  <KTSVG
                    path="/media/icons/duotune/arrows/send-message.svg"
                    className="svg-icon-2 svg-icon-gray-500"
                  />
                </button>
              </div>
            </div>
            {/* <textarea
                  className='form-control form-control-flush mb-3'
                  rows={1}
                  data-kt-element='input'
                  placeholder='Type a message'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={onEnterPress}
                ></textarea>

                <div className='d-flex flex-stack'>
                  <div className='d-flex align-items-center me-2'>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary me-1'
                      type='button'
                      data-bs-toggle='tooltip'
                      title='Coming soon'
                    >
                      <i className='bi bi-paperclip fs-3'></i>
                    </button>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary me-1'
                      type='button'
                      data-bs-toggle='tooltip'
                      title='Coming soon'
                    >
                      <i className='bi bi-upload fs-3'></i>
                    </button>
                  </div>
                  <button
                    className='btn btn-primary'
                    type='button'
                    data-kt-element='send'
                    onClick={sendMessage}
                  >
                    Send
                  </button>
                </div> */}
          </div>
        </div>
      </>
    );
  }
  return <></>;
};

export { ChatInner };
