import { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { useParams,useLocation } from 'react-router-dom'
import {AsideMenuItem} from './AsideMenuItem'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useContext } from 'react'
import { SidebarContext } from './SidebarContext'




export function AsideMenuMain() {
  const intl = useIntl()
  const [slug1, setSlug] = useState<string>('');
  const [summary,setSummary] = useState({total:'',support:'',lead:''})
  const location = useLocation();
  const user = useSelector((state:any) => state.auth.user);
  const {isSidebarOpen, toggleOpen, isDashboardVisible, toggleDashboard} = useContext<any>(SidebarContext)
  const API_URL = process.env.REACT_APP_API_URL;
  const { slug } = useParams();
  console.log(slug);
  

  
  
  // useEffect(()=>{
  //   const fetchSummary = async () => {
  //     try {
  //       const SUMMARY = `${API_URL}/get-conversation-stats/${slug1 ? slug1 : user.company.slug}/`
  //       const response = await axios.get(SUMMARY);
  //       console.log(response.data);
        
  //       if (response?.data) {
          
  //         console.log(response.data.data);
  //         setSummary(response.data.data);
          
  //       } else {
          
  //         console.error("Invalid response format:", response);
  //       }
  //     } catch (error:any) {
       
  //       console.log(error);
  //     }
  //   }
  //   fetchSummary()
  // },[location.pathname])


  //
 
  

  useEffect(() => {
    const path = location.pathname;
    let slugFromURL = '';

    if (path.startsWith('/chat/lead')) {
      slugFromURL = path.replace('/chat/lead', '');
    } else if (path.startsWith('/chat/support')) {
      slugFromURL = path.replace('/chat/support', '');
    } else if (path.startsWith('/chat/oneinbox')) {
      slugFromURL = path.replace('/chat/oneinbox', '');
    }
    console.log(path, slugFromURL)
    setSlug(slugFromURL.replace('/', ''));
    const fetchSummary = async () => {
      try {
        console.log(slugFromURL);
        
        const SUMMARY = `${API_URL}/get-conversation-stats/${slugFromURL.length>1 ? slugFromURL.replace('/','') : user.company.slug}/`
        const response = await axios.get(SUMMARY);
        console.log(response.data);
        
        if (response?.data) {
          
          console.log(response.data.data);
          setSummary(response.data.data);
          
        } else {
          // setConversationList([]);
          console.error("Invalid response format:", response);
        }
      } catch (error:any) {
        // setConversationList([]);
        console.log(error);
      }
    }
    fetchSummary()
  }, [location.pathname]);

  useEffect(()=>{
    console.log("mounted");
    
  },[])
  return (
    <>
    {slug1?
    <>
        <AsideMenuItem
          to={`/chat/oneinbox/${slug1}`}
          title='All'
          bsTitle='All Chat'
          fontIcon='chat'
          className='mb-6'
          messageSummary = {summary['total']}
        />
        <AsideMenuItem
          to={`/chat/support/${slug1}`}
          title='Support'
          bsTitle='Support Chat'
          fontIcon='customer-service'
          className='mb-6'
          messageSummary={summary['support']}
      
        />
        <AsideMenuItem
          to={`/chat/lead/${slug1}`}
          title='Lead'
          bsTitle='Lead Chat'
          fontIcon='customer'
          className='mb-6'
          messageSummary={summary['lead']}
      
        />
      </>
      :<>
      <AsideMenuItem
        to='/chat/oneinbox'
        title='All'
        bsTitle='All Chat'
        fontIcon='chat'
        className='mb-6'
        messageSummary = {summary['total']}
      />
      <AsideMenuItem
        to='/chat/support'
        title='Support'
        bsTitle='Support Chat'
        fontIcon='customer-service'
        className='mb-6'
        messageSummary={summary['support']}
      />
      <AsideMenuItem
        to='/chat/lead'
        title='Lead'
        bsTitle='Lead Chat'
        fontIcon='customer'
        className='mb-6'
        messageSummary={summary['lead']} 
      />
    </>
      }
      <AsideMenuItem
        to='#'
        title='Campaign Soon'
        bsTitle='Campaign Soon'
        fontIcon='advertising'
        className='mb-6'
       
      />
    </>
  )
}
