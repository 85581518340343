import { createContext } from 'react';

interface SidebarContextType {
  isSidebarOpen: boolean | null;
  toggleOpen: (value:boolean) => void;
  isDashboardVisible: boolean | null;
  toggleDashboard: (value:boolean) => void;
}

export const SidebarContext = createContext<SidebarContextType | null>(null);
