import {Suspense, useEffect} from 'react'
import {Outlet, useLocation, useParams} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {I18nProvider} from '../_theme/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_theme/layout/core'
import {MasterInit} from '../_theme/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import * as conv from './modules/auth/redux/ConvRedux'

const App = () => {
  // const {token,conversationId} = useParams();
  // console.log(token,conversationId);
  const location = useLocation();
  const dispatch = useDispatch();
  
  useEffect(()=>{
    const matchWithId = location.pathname.match(/^\/auth\/token\/([^/]+)\/([^/]+)$/);
    if(matchWithId?.length==3){
       dispatch(conv.convactions.SET_CONVERSATION_ID(matchWithId[2]));
    }
    
    
  },[])
  
  
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}
export {App}
