import {FC, useContext, useEffect} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'
import { SidebarContext } from './SidebarContext'
import { useMediaQuery } from 'react-responsive'


type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  className?: string
  hasBullet?: boolean
  bsTitle?: string
  outside?: boolean
  active? : boolean
  messageSummary?: string
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  className,
  bsTitle,
  outside = false,
  hasBullet = false,
  messageSummary,
  active
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
 const {isSidebarOpen, toggleOpen, isDashboardVisible, toggleDashboard} = useContext<any>(SidebarContext)
 const isTablet = useMediaQuery({ query: '(max-width: 990px)' })
 const isMobile =  useMediaQuery({ query: '(max-width: 767px)' })
  
  const {aside} = config

  return (
    // <OverlayTrigger
    //   placement='right'
    //   delay={{show: 250, hide: 400}}
    //   overlay={(props) => (
    //     <Tooltip id='button-tooltip' {...props}>
    //       {bsTitle}
    //     </Tooltip>
    //   )}
    // >
      <div className={clsx('aside_menu_item menu-item w-100 px-sm-8 px-md-3 py-4', className, isActive && 'selected', !isActive && 'unselected')}
      
      >
        {outside ? (
          <a
            href={to}
            target='_blank'
            style={{padding: '0.5rem 0rem'}}
            className={clsx('menu-center', {active: isActive})}
          >
            {fontIcon && aside.menuIcon === 'font' && (
              <span className='menu-icon me-0'>
                <i className={clsx('bi', fontIcon, 'fs-2')}></i>
              </span>
            )}
          </a>
        ) : (
          <>
            <Link 
              // onClick={() => toggleOpen(false)}
              className={clsx(`menu-center`, {active: isActive})}
              to={to}
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              // data-bs-dismiss='click'
              data-bs-placement='right'
              data-bs-original-title={bsTitle}
              
              
            >
              {hasBullet && (
                <span className='menu-bullet'>
                  <span className='bullet bullet-dot'></span>
                </span>
              )}
              {icon && aside.menuIcon === 'svg' && (
                <span className='menu-icon'>
                  <KTIcon iconName={icon} className='fs-2' />
                </span>
              )}
              {/* {fontIcon ? (
                <span className='menu-icon me-0'>
                  <i className={clsx('bi', fontIcon, 'fs-2')}></i>
                </span>
              ) : (
                <span className='menu-title'>{title}</span>
              )} */}
              {
                fontIcon ? (
                  <div className={`menu-icon me-0 d-flex gap-8 align-items-center text-center gap-2 ps-lg-4 ps-md-2 ps-7`}>
                    <div style={{position: 'relative'}}>
                      <div>
                    <img src={`${process.env.PUBLIC_URL}/media/aside_icons/${fontIcon}.png`} alt='chat' style={{width: '23px', height: '23px'}}></img>
                    </div>
                      {!title.includes('Soon')  && messageSummary!='0' && <div className='summary rounded-circle' >{messageSummary}</div>}
                    </div>
                    {!title.includes('Soon')  ? <span style={{fontSize: "15px", color: 'black', whiteSpace: 'nowrap', overflow: 'hidden', transition: 'opacity 0.6s ease-in-out'}} className={`${isSidebarOpen?'opacity-1':'opacity-0'}'}`}>{title.replace('Soon','')}</span>
                    : <span style={{fontSize: "15px", color: 'black', whiteSpace: 'nowrap', overflow: 'hidden', transition: 'opacity 0.6s ease-in-out'}} className={`${isSidebarOpen?'opacity-1':'opacity-0'}'}`}>{title.replace('Soon','')}<sup style={{color:"#045540"}}>SOON</sup></span>}
                  </div>
                )
                : (
                  <span className='menu-title'>{title}</span>
                )
              }
              
            </Link>
            {children}
          </>
        )}
      </div>
    // </OverlayTrigger>
  )
}

export {AsideMenuItem}

