import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { AsideMenuMain } from "./AsideMenuMain";
import {
  DrawerComponent,
  ToggleComponent,
} from "../../../assets/ts/components";
import { useContext } from "react";
import { SidebarContext } from "./SidebarContext";

type Props = {
  asideMenuCSSClasses: string[];
  // collapsed: boolean
};

const AsideMenu2: React.FC<Props> = () => {
  const { pathname } = useLocation();
  const { isSidebarOpen, toggleOpen, isDashboardVisible, toggleDashboard } =
    useContext<any>(SidebarContext);
  // console.log(collapsed);

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
    }, 50);
  }, [pathname]);

  return (
    <div
      id="kt_aside_menu"
      className="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-6 align-items-start"
      data-kt-menu="true"
      onMouseEnter={() => {
        if (!isSidebarOpen) toggleOpen(true);
      }}
      onMouseLeave={() => {
        if (isSidebarOpen) toggleOpen(false);
      }}
      style={{ pointerEvents: "auto" }}
    >
      <AsideMenuMain />
    </div>
  );
};

export { AsideMenu2 };
