import { useState } from "react"
import { SidebarContext } from "../components/aside/SidebarContext"
export const SidebarProvider = ({ children }:{children:React.ReactNode}) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean | null>(null)
    const [isDashboardVisible, setIsDashboardVisible] = useState<boolean | null>(null)
    const toggleDashboard = (value:boolean) => setIsDashboardVisible(value)
    const toggleOpen = (value:boolean) => setIsSidebarOpen(value)
    const values = { isSidebarOpen, toggleOpen,isDashboardVisible,toggleDashboard }
   
    return (
        <SidebarContext.Provider value={values}>
            {children}
        </SidebarContext.Provider>
    )
}