import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Route, Routes, useNavigate} from 'react-router-dom'
import * as auth from './redux/AuthRedux'
import { AuthPage } from './AuthPage'

export function Logout() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  useEffect(() => {
    const logoutAndNavigate = async () => {
      // Dispatching the logout action (assuming it returns a promise)
      await dispatch(auth.actions.logout());

      // After the logout is complete, navigate to the desired route
      setTimeout(() => {
        document.location.reload()
      }, 100);
    };

    logoutAndNavigate();
  }, [dispatch])

  return (
    <></>
  )
}
