import {FC} from 'react'

const Error404: FC = () => {
  return (
    <>
      <div className="four_zero_four_bg">
        <h1 className="text-center ">404</h1>
      </div>
      <div className="contant_box_404">
        <h3 className="h2">Look like you're lost</h3>
        <p>the page you are looking for is not available!</p>
        <a href="/app/chat/oneinbox/" className="link_404">Go to Home</a>
      </div>
    </>
  )
}

export {Error404}
