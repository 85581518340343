import {FC} from 'react'

const Error500: FC = () => {
  return (
    <>
      <div className="four_zero_four_bg">
        <h1 className="text-center ">500</h1>
      </div>
      <div className="contant_box_404">
        <h3 className="h2">Internal Error</h3>
        {/* <p>the page you are looking for not avaible!</p> */}
        <a href="/" className="link_404">Go to Home</a>
      </div>
    </>
  )
}

export {Error500}
