import React, { useContext } from 'react'
import { AsideMenuMain } from './AsideMenuMain'
import { NavbarMenuMain } from './NavbarMenuMain'
import { SidebarContext } from './SidebarContext'

const NavbarPhone = () => {
  const {isSidebarOpen,toggleOpen,isDashboardVisible,toggleDashboard} = useContext<any>(SidebarContext)
  return (
    <div>
      <div className={`bg-white shadow-lg navbarPhone ${isDashboardVisible ? "" : 'd-none'}`}>
        {/* <h1>Hello</h1> */}
        <div className='d-flex justify-content-between px-4 py-2'>
        <NavbarMenuMain />
        </div>
      </div>
    </div>
  )
}

export default NavbarPhone
