import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { select } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";

// export interface ActionWithPayload<T> extends Action {
//   payload?: T
// }

export const initialConversation = {
  conversationId: null,
};

export const convreducer = persistReducer(
  { storage, key: "v100-demo1-auth", whitelist: ["conversationId"] },
  (state: any = initialConversation, action: any) => {
    switch (action.type) {
      case "SET_CONVERSATION_ID": {
        console.log(action.payload);
        const conversationId = action.payload;
        console.log(conversationId);

        state.conversationId = conversationId;
        console.log(state);

        return {
          ...state,
        };
      }

      case "GET_CONVERSATION_ID": {
        return { ...state };
      }

      default:
        return state;
    }
  }
);

export const convactions = {
  SET_CONVERSATION_ID: (conversationId: any) => ({
    type: "SET_CONVERSATION_ID",
    payload: conversationId,
  }),

  GET_CONVERSATION_ID: () => ({ type: "GET_CONVERSATION_ID" }),
};

export function* convoSaga() {
  yield takeLatest("GET_CONVERSATION_ID", function* handleIdRequest() : any {
    const id = yield select((state) => state.conv.conversationId);
    console.log("Current id:", id);
  });
}
