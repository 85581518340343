// PrivateRoutes.tsx

import { FC, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as auth from '../redux/AuthRedux'

const SetTokenId: FC = () => {
  const dispatch = useDispatch();
  const { token,conversationid } = useParams();
    
  useEffect(() => {
    // console.log(token,conversationid);
    // const id =  dispatch(conv.convactions.SET_CONVERSATION_ID(conversationid));
    // console.log(id);
    
    setTimeout(() => {
        dispatch(auth.actions.login(token));
    }, 1000)
  }, []);

  return (
    <>
        <h2>Fetching Details</h2>
    </>
  );
};

export {SetTokenId};
