import { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { useParams,useLocation } from 'react-router-dom'
import {NavbarItemPhone} from './NavbarItemPhone'
import axios from 'axios'
import { useSelector } from 'react-redux'


export function NavbarMenuMain() {
  const intl = useIntl()
  const [slug1, setSlug] = useState<string>('');
  const [summary,setSummary] = useState<any>({});
  const location = useLocation();
  const API_URL = process.env.REACT_APP_API_URL;
  const user = useSelector((state:any) => state.auth.user);
  const { slug } = useParams();
  const SUMMARY = `${API_URL}/get-conversation-stats/${slug1 ? slug1 : user.company.slug}/`
  
  useEffect(()=>{
    const fetchSummary = async () => {
      try {
        const response = await axios.get(SUMMARY);
        console.log(response.data);
        
        if (response?.data) {
          
          console.log(response.data.data);
          setSummary(response.data.data);
          
        } else {
          // setConversationList([]);
          console.error("Invalid response format:", response);
        }
      } catch (error:any) {
        // setConversationList([]);
        console.log(error);
      }
    }
    fetchSummary()
  },[])
 
  

  useEffect(() => {
    const path = location.pathname;
    let slugFromURL = '';

    if (path.startsWith('/chat/lead')) {
      slugFromURL = path.replace('/chat/lead', '');
    } else if (path.startsWith('/chat/support')) {
      slugFromURL = path.replace('/chat/support', '');
    } else if (path.startsWith('/chat/oneinbox')) {
      slugFromURL = path.replace('/chat/oneinbox', '');
    }
    console.log(path, slugFromURL)
    setSlug(slugFromURL.replace('/', ''));
  }, [location.pathname]);
  return (
    <>
    {slug1?
    <>
        <NavbarItemPhone
          to={`/chat/oneinbox/${slug1}`}
          title='All'
          bsTitle='All Chat'
          fontIcon='chat'
          className=''
          messageSummary = {summary['total']}
         
        />
        <NavbarItemPhone
          to={`/chat/support/${slug1}`}
          title='Support'
          bsTitle='Support Chat'
          fontIcon='customer-service'
          className='mb-6'
          messageSummary = {summary['total']}
      
        />
        <NavbarItemPhone
          to={`/chat/lead/${slug1}`}
          title='Lead'
          bsTitle='Lead Chat'
          fontIcon='customer'
          className='mb-6'
          messageSummary = {summary['total']}
         
        />
      </>
      :<>
      <NavbarItemPhone
        to='/chat/oneinbox'
        title='All'
        bsTitle='All Chat'
        fontIcon='chat'
        className='mb-6'
        messageSummary = {summary['total']}
       
      />
      <NavbarItemPhone
        to='/chat/support'
        title='Support'
        bsTitle='Support Chat'
        fontIcon='customer-service'
        className='mb-6'
        messageSummary = {summary['total']}
   
      />
      <NavbarItemPhone
        to='/chat/lead'
        title='Lead'
        bsTitle='Lead Chat'
        fontIcon='customer'
        className='mb-6'
        messageSummary = {summary['total']}
        
      />
    </>
      }
      <NavbarItemPhone
        to='#'
        title='Campaign Soon'
        bsTitle='Campaign Soon'
        fontIcon='advertising'
        className='mb-6'
        messageSummary = {summary['total']}
       
      />
    </>
  )
}
