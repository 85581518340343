import { FC, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useLayout } from "../../core";
import { useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { useMediaQuery } from "react-responsive";
import { Dropdown3 } from "../../../partials";
import { AsideMenu2 } from "./AsideMenu2";
import { SidebarContext } from "./SidebarContext";
// import logout_logo from '../../../../../public/media/logos/logout_logo.jpg'
// import { useActive } from './SidebarContext'
{
  /* <link rel="stylesheet" href="%PUBLIC_URL%/aside.css"  type = "text/css" /> */
}
// import '../../../../../public/aside.css'

const AsideDefault: FC = () => {
  const { classes } = useLayout();
  const { isSidebarOpen, toggleOpen } = useContext<any>(SidebarContext);
  console.log(isSidebarOpen);
  const pathname = window.location.pathname;
  const isTablet = useMediaQuery({ query: "(max-width: 991px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div
      id="kt_aside"
      className={clsx(
        "aside overflow-visible bg-white",
        classes.aside.join(" "),
        isSidebarOpen ? "" : "collapsed"
      )}
      // data-kt-drawer="false"
      // data-kt-drawer-name="aside"
      // data-kt-drawer-activate="{default: true}"
      // data-kt-drawer-overlay="true"
    
      // data-kt-drawer-direction="start"
      // data-kt-drawer-toggle="#kt_aside_toggle"
     
    >
      {/* begin::Logo */}
      <div
        className="aside-logo d-flex flex-column flex-column-auto gap-4 py-8"
        id="kt_aside_logo"
      >
        <Link to="/chat/oneinbox">
          <img
            src={toAbsoluteUrl("/media/logos/Logo.png")}
            alt="logo"
            className={`${!isTablet ? "ps-6" : "ps-2"} ${
              isMobile ? "ms-7" : ""
            } h-54px w-60px`}
          />
        </Link>
        <i
          className={clsx(
            "bi bi-list d-none d-md-flex cursor-pointer",
            "fs-1 text-black",
            !isTablet ? "ps-8" : "ps-7"
          )}
          style={{pointerEvents: 'auto'}}
          onClick={() => toggleOpen(!isSidebarOpen)}
        ></i>
      </div>
      {/* end::Logo */}

      {/* begin::Nav */}
      <div
        className="asaside-nav d-flex flex-column align-lg-center flex-column-fluid pt-5 pt-lg-0"
        id="kt_aside_nav"
      >
        <AsideMenu2 asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Nav */}

      {/* begin::Footer */}
      <div
        className=" d-flex flex-column mb-7 gap-4 flex-column-auto"
        id="kt_aside_footer"
      >
        {/* begin::Menu */}
        <div className="ps-5" style={{pointerEvents: 'auto'}}>
          <button
            type="button"
            className="btn btm-sm btn-icon profile_button"
            data-kt-menu-trigger="click"
            data-kt-menu-overflow="true"
            data-kt-menu-placement="top-start"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-dismiss="click"
            title="Quick actions"
          >
            <i className="bi bi-person-circle fs-2"></i>
          </button>
          <Dropdown3 />
        </div>
        <div className="ps-5">
          <img
            className="w-40px h-40px rounded-circle"
            src={process.env.PUBLIC_URL + "/media/logos/logout_logo.jpg"}
            alt="logout_logo"
          />
        </div>
        {/* end::Menu */}
      </div>
      {/* end::Footer */}
    </div>
  );
};

export { AsideDefault };
