// PrivateRoutes.tsx

import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as auth from '../redux/AuthRedux'

const SetToken: FC = () => {
  const dispatch = useDispatch();
  const { token } = useParams(); // Extract token from URL params

  useEffect(() => {
    // Dispatch the action to set the token in the Redux store
    setTimeout(() => {
        dispatch(auth.actions.login(token));
    }, 1000)
  }, []);

  return (
    <>
        <h2>Fetching Details</h2>
    </>
  );
};

export {SetToken};
