import {FC, useEffect, useState} from "react"
import { Form, Modal} from "react-bootstrap"
import axios from "axios";



const TemplateMessageModal: FC<any> = ({show, handleClose, customerProfile,slug}) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const TEMPLATE_LIST_API_URL = `${API_URL}/get-template-list/${slug}/${customerProfile.conversation_id}/`
    // const HOST_URL = API_URL?.slice(0, -4);
    const [activeTab, setActiveTab] = useState('kt_tab_pane_4');

    const handleTabClick = (tabId:any) => {
        setActiveTab(tabId);
    };
    // const BULK_MESSAGE_API = `${HOST_URL}/dashboard/${company}/events-name/`;

    const [inputString, setInputString] = useState('Hi {{1}},\nThanks for onboarding this experience, hosted by {{2}}. I hope you enjoy every bit of it.\nBooking Id: {{3}}\nBooked By: {{4}}\nEvent Name: {{5}}\nAmount Paid: INR {{6}}\nAmount Pending: INR {{7}}\nFor more details visit: {{8}}\nWe have sent a booking confirmation mail to {{9}} containing all the details.');
    const [inputString2, setInputString2] = useState('Hi {{1}},\nThanks for onboarding this experience, hosted by {{2}}. I hope you enjoy every bit of it.\nBooking Id: {{3}}\nBooked By: {{4}}\nEvent Name: {{5}}\nAmount Paid: INR {{6}}\nAmount Pending: INR {{7}}\nFor more details visit: {{8}}.');
    const [placeholders, setPlaceholders] = useState<any>({});
    const [selectedTemplate, setSelectedTemplate] = useState<any>()
    const [templateList, setTemplateList] = useState<any>()

    const handleChange = (e:any) => {
        setInputString(e.target.value);
        generatePlaceholders(e.target.value);
    };

    const handleCardClick = (template:any) => {
        // setInputString(text);
        setSelectedTemplate(template)
        generatePlaceholders(template.text);
    };

    const generatePlaceholders = (str:any) => {
        const placeholderCount = str.split('{{').length - 1;
        let newPlaceholders:any = {};
        for (let i = 1; i <= placeholderCount; i++) {
        newPlaceholders[i] = placeholders[i] || `{{${i}}}`;
        }
        setPlaceholders(newPlaceholders);
    };

    const handlePlaceholderChange = (e:any) => {
        const { name, value } = e.target;
        // console.log(renderPreview())
        setPlaceholders((prevState:any) => ({
        ...prevState,
        [name]: value,
        }));
    };

    const renderPreview = (text:any) => {
        let previewString = text;
        Object.keys(placeholders).forEach(key => {
          const regex = new RegExp(`{{${key}}}`, 'g');
          previewString = previewString.replace(`{{${key}}}`, placeholders[key]);
        });
        return previewString;
      };
    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log(TEMPLATE_LIST_API_URL)
                const response = await axios.get(TEMPLATE_LIST_API_URL);
                console.log(response)
                if (response?.data) {
                    setTemplateList(response.data.templates)
                }
            } 
            catch (error) {
              console.log(error);
            }
          };
      
          fetchData();
    },[])
    const handleSubmit = () => {
        // setLinkInMessage(option)
        handleClose()
    };
    if(templateList){
        return (
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header>
                    <h3>Send Template Message</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-9 col-md-12 col-sm-12">
                            <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 'kt_tab_pane_4' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('kt_tab_pane_4')}
                                        href="#kt_tab_pane_4"
                                    >
                                        All
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 'kt_tab_pane_5' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('kt_tab_pane_5')}
                                        href="#kt_tab_pane_5"
                                    >
                                        Link 2
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 'kt_tab_pane_6' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('kt_tab_pane_6')}
                                        href="#kt_tab_pane_6"
                                    >
                                        Link 3
                                    </a>
                                </li> */}
                            </ul>
                            <div className="tab-content h-200px overflow-auto" id="myTabContent">
                                <div
                                    className={`tab-pane fade ${activeTab === 'kt_tab_pane_4' ? 'show active' : ''}`}
                                    id="kt_tab_pane_4"
                                    role="tabpanel"
                                >
                                    {templateList.map((template:any, idx: any) => {
                                        return <div className="card card-custom card-flush mt-2 cursor-pointer" onClick={() => {handleCardClick({"title":template?.title?template.title: "Title: NA", "text": template.text})}} key={idx}>
                                            <div className="card-header">
                                                <h3 className="card-title">{template?.title?template.title: "Title: NA"}</h3>
                                                <div className="card-toolbar">
                                                    <h4>{template?.type}</h4>
                                                </div>
                                            </div>
                                            <div className="card-body py-5">
                                                {`${template.text.slice(0,50)}...`}
                                            </div>
                                        </div>
                                    })}
                                    {/* <div className="card card-custom card-flush mt-2" onClick={() => {handleCardClick({"title":"Template 2", "text": inputString2})}}>
                                        <div className="card-header">
                                            <h3 className="card-title">Template Title 2</h3>
                                            <div className="card-toolbar">
                                                <h4>Type of Template</h4>
                                            </div>
                                        </div>
                                        <div className="card-body py-5">
                                            Template Message preview Lorem Ipsum is simply dummy text...
                                        </div>
                                    </div>

                                    <div className="card card-custom card-flush mt-2" onClick={() => {handleCardClick({"title":"Template 1", "text": inputString})}}>
                                        <div className="card-header">
                                            <h3 className="card-title">Template Title 2</h3>
                                            <div className="card-toolbar">
                                                <h4>Type of Template</h4>
                                            </div>
                                        </div>
                                        <div className="card-body py-5">
                                            Template Message preview Lorem Ipsum is simply dummy text...
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className={`tab-pane fade ${activeTab === 'kt_tab_pane_5' ? 'show active' : ''}`} id="kt_tab_pane_5" role="tabpanel">
                                    Temp2
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'kt_tab_pane_6' ? 'show active' : ''}`} id="kt_tab_pane_6" role="tabpanel">
                                    Temp3
                                </div> */}
                            </div>
                            <Form>
                                {selectedTemplate?
                                    <h3 className="mt-4">{selectedTemplate.title}</h3>
                                :""
                                }
                                {Object.keys(placeholders).map(key => (
                                <Form.Group key={key} controlId={key}>
                                    <Form.Label>Placeholder {key}:</Form.Label>
                                    <Form.Control type="text" name={key} value={placeholders[key]==`{{${key}}}`?"": placeholders[key]} onChange={handlePlaceholderChange} />
                                </Form.Group>
                                ))}
                            </Form>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="card card-custom card-flush mt-2">
                                <div className="card-header">
                                    <h3 className="card-title">Message Preview</h3>
                                </div>
                                <div className="card-body py-5">
                                    <pre>
                                        {selectedTemplate?renderPreview(selectedTemplate.text): "Please select a Template"}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary">Send Message</button>
                </Modal.Footer>
            </Modal>
        )
    }
    return <></>
}

export {TemplateMessageModal}