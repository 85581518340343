import {FC, useEffect, useState} from "react"
import { Dropdown, Modal } from "react-bootstrap"
import axios from "axios";


const LeadAssignModal: FC<any> = ({show, handleClose, slug, conversation_id, assigned_to, customer_profile}) => {
    const [value, setValue] = useState<any>(assigned_to);
    const [salesTeam, setSalesTeam] = useState<any>();
    const API_URL = process.env.REACT_APP_API_URL;
    const GET_SALES_TEAM_API = `${API_URL}/get-sales-team-mate/${slug}/`
    const ASSIGN_CONVERSATION_OWNER_API = `${API_URL}/assign-conversation-owner/${slug}/${conversation_id}/`

    useEffect(() => {
        const response = axios.get(GET_SALES_TEAM_API);
        response.then((data:any)=>{
          setSalesTeam(data.data.response)
        })
      }, []);
    const assignLead = (event: any) => {
        console.log(event.target.value);
        const response = axios.get(`${ASSIGN_CONVERSATION_OWNER_API}${event.target.value}/${customer_profile}/`);
        response.then((data:any)=>{
          window.location.reload()
        })

    }
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
                <h3>Assign Lead</h3>
            </Modal.Header>
            <Modal.Body>
                <select className='form-select form-select-solid form-select-sm' onChange={assignLead} value={value}>
                    <option value=''>Select a Assignee</option>
                    {salesTeam?.map((team:any, index: any) => {
                    return <option key={index} value={team.peopleid}>{team.username}</option>
                    })}
                </select>
            </Modal.Body>
        </Modal>
    )
}

export {LeadAssignModal}