/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG } from '../../../helpers'
import { useDispatch } from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

const Dropdown3: FC = () => {
  const dispatch = useDispatch()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 fw-bold w-200px py-3'
      data-kt-menu='true'
    >
      {/* <div className='menu-item px-3'>
        <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>Payments</div>
      </div> */}
      <div className='menu-item px-3 profile_dropdown'>
        <a
          href="https://logout.world"
          className='menu-link flex-stack px-3'
        >
          Go to Dashboard
          <KTSVG
            path='/media/icons/duotune/arrows/arr036.svg'
            className='svg-icon-2 svg-icon-gray-400'
          />
        </a>
      </div>
      <div className='menu-item px-3  profile_dropdown'>
        <Link to="/logout" className='menu-link flex-stack px-3'>
          Logout
          <KTSVG
            path='/media/icons/duotune/arrows/arr076.svg'
            className='svg-icon-2 svg-icon-gray-400'
          />
        </Link>
      </div>

      
    </div>
  )
}

export {Dropdown3}
