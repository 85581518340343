import React, { useEffect } from "react";
import { PageDataProvider } from "./core";
import { Outlet, useLocation } from "react-router-dom";
import { MenuComponent } from "../assets/ts/components";
import HeaderBase from "./components/header/HeaderBase";
import { ScrollTop } from "./components/Scrolltop";
import { Content } from "./components/Content";
import { useMediaQuery } from "react-responsive";
import { AsideDefault } from "./components/aside/AsideDefault";
import { SidebarProvider } from "./core/SidebarProvider";
import NavbarPhone from "./components/aside/NavbarPhone";

const MasterLayout: React.FC = ({ children }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <div className={`d-flex flex-column flex-root  bg-custom-white`}>
        <div className="page d-flex flex-row flex-column-fluid">
          {/* <AsideBase /> */}
          <SidebarProvider>
            <AsideDefault />

            <div
              className={`wrapper d-flex flex-column flex-row-fluid ps-lg-20`}
              id="kt_wrapper"
              style={{
                backgroundImage:
                  'url("https://i.pinimg.com/564x/f9/65/30/f96530be17e1f5a28150fdf432c96f87.jpg")',
                boxShadow: "inset 0 0 0 2000px rgba(246,246,248, 0.5)",
                paddingLeft: "3.3rem",
              }}
            >
              {isMobile && <HeaderBase />}
              {isMobile && <NavbarPhone />}
              {/* <HeaderBase /> */}
              <div
                className="content d-flex flex-column flex-column-fluid pb-0"
                id="kt_content"
              >
                <Content>
                  <Outlet />
                </Content>
              </div>

              {/* {!isMobile && <Footer />} */}
            </div>
          </SidebarProvider>
        </div>
      </div>
      {/* begin:: Drawers */}
      {/* <ActivityDrawer />
      <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* <CreateApp /> */}
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
