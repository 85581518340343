import React, { useContext } from 'react'
import HeaderTopbar from './HeaderTopbar'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'
import {Dropdown3} from '../../../partials/content/dropdown/Dropdown3'
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { SidebarContext } from '../aside/SidebarContext'
// import { Dropdown3 } from '../../../partials/content/dropdown/Dropdown3'

const HeaderBase: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const {isSidebarOpen, toggleOpen} = useContext<any>(SidebarContext)
  const dispatch = useDispatch()
  const temp = () => {
    dispatch(auth.actions.logout())
  }

  // const {isSidebarOpen, toggleOpen} = useContext<any>(SidebarContext)
  return (
    <div id='kt_header' className='header align-items-stretch'>
      {isMobile &&
        <div className='header-brand'>
          <Link to='/'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/Logo.png')} className='h-45px' />
          </Link>

          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            {/* <div
              className='btn btn-icon btn-active-color-primary w-30px h-30px'
              id='kt_aside_mobile_toggle'
            >
              <button
              className='btn btn-icon btn-color-gray-500 w-auto px-0 btn-active-color-primary'
              data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
              data-kt-menu-placement='bottom-start'
              data-kt-menu-overflow='true'
            >
              <KTSVG path='/media/icons/duotune/general/gen023.svg' className='svg-icon-1 me-n1' />
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1 me-n1' />
            </button>
            <Dropdown3/>
            </div> */}
            {/* <div
              className='btn btn-icon btn-active-color-primary w-30px h-30px'
              id='kt_aside_toggle'
              
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
            </div> */}
            <div className="ps-5">
          <button
            type="button"
            className="btn btm-sm btn-icon profile_button"
            data-kt-menu-trigger="click"
            data-kt-menu-overflow="true"
            data-kt-menu-placement="top-start"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-dismiss="click"
            title="Quick actions"
          >
            <i className="bi bi-person-circle fs-2"></i>
          </button>
          <Dropdown3 />
        </div>
          </div>
        </div>
      }
      {  !isMobile &&
        <div className='topbar'>
          <HeaderTopbar />
        </div>
      }
    </div>
  )
}

export default HeaderBase
