/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {KTSVG} from '../../../helpers'
import {PageTitle} from './page-title/PageTitle'
import {HeaderNotificationsMenu, QuickLinks, Search} from '../../../partials'
import {useLayout} from '../../core'
import { useDispatch } from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

const HeaderTopbar: React.FC = () => {
  const {classes} = useLayout()
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }
  return (
    <div
      className={`${classes.headerContainer} py-6 py-lg-0 d-flex flex-column flex-sm-row align-items-lg-stretch justify-content-sm-between`}
    >
      <PageTitle />

      <div className='d-flex align-items-center overflow-auto pt-3 pt-sm-0'>
        {/* <div className='header-search me-4'>
          <Search />
        </div> */}

        <div className='d-flex'>
          <div className='d-flex align-items-center me-4'>
            {/* eslint-disable-next-line */}
            <a
              href="https://logout.world"
              className='btn btn-icon btn-active-color-primary me-n4'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr036.svg'
                className='svg-icon-2 svg-icon-gray-400'
              />
            </a>
            <button
              onClick={() => {
                logout()
              }}
              className='btn btn-icon btn-active-color-primary me-n4'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr076.svg'
                className='svg-icon-2 svg-icon-gray-400'
              />
            </button>
          </div>

          {/* <div className='d-flex align-items-center'>
            <button
              onClick={() => {
                Logout()
              }}
              className='btn btn-icon btn-active-color-primary me-n4'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr076.svg'
                className='svg-icon-2 svg-icon-gray-400'
              />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default HeaderTopbar
