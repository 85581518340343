import {FC, useEffect, useState} from "react"
import { Dropdown, Modal } from "react-bootstrap"
import axios from "axios";



const EventModal: FC<any> = ({show, handleClose, company, customerProfile, setLinkInMessage}) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const HOST_URL = API_URL?.slice(0, -4);
    const EVENTS_LIST_API = `${HOST_URL}/dashboard/${company}/events-name/`;
    const SEND_TEMPLATE_MESSAGE_API = `${API_URL}/send-template-message/`
    const GET_EVENT_DETAILS_API = `${API_URL}/get-event-details`
    const TEMPLATE_LIST_API_URL = `${API_URL}/get-template-list/${company}/${customerProfile.conversation_id}/`

    const initialState = {
        isTemplateFlow: false,
        selectedEvent: {"slug":"","name":""}
    };

    const [eventList, setEventList] = useState<any>();
    const [isTemplateFlow, setIsTemplateFlow] = useState<Boolean>();
    const [selectedEvent, setSelectedEvent] = useState<any>({"slug":"","name":""});
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedEventDetails, setSelectedEventDetails] = useState<any>();
    const [loading, setLoading] = useState<any>(false);
    const [templateList, setTemplateList] = useState<any>()
    const [hasSendItinerary, setHasSendItinerary] = useState<any>();
    function checkSendItinerary(array:any) {
        const sendItineraryObject = array.find((obj:any) => obj.category == "send_itinerary");
        return sendItineraryObject ? sendItineraryObject.template_id : null;
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(EVENTS_LIST_API);
                const templateApiResponse = await axios.get(TEMPLATE_LIST_API_URL);
                if (templateApiResponse?.data) {
                    setHasSendItinerary(checkSendItinerary(templateApiResponse.data.templates))
                }
                if (response?.data) {
                    setEventList(response.data)
                }
            } 
            catch (error) {
              console.log(error);
            }
          };
      
          fetchData();
    },[])

    const resetState = () => {
        setIsTemplateFlow(initialState.isTemplateFlow);
        setSelectedEvent(initialState.selectedEvent);
        setErrorMessage('')
    };

    const sendTemplateMessage = async (eventDetails:any) => {
        setLoading(true)
        if (selectedEvent.slug?.length==0) {
            setErrorMessage('Please select an event.');
            setLoading(false)
        }
        else if(!hasSendItinerary){
            setErrorMessage('Please Contact Logout Support to get the template Message Approved');
            setLoading(false)
        } else {
            eventDetails.slug = selectedEvent.slug
            eventDetails.company = company
            const response = await axios.post(SEND_TEMPLATE_MESSAGE_API,{"event_details": eventDetails, "mobile": customerProfile?.mobile, "template_id": hasSendItinerary});
            setLoading(false)
            resetState()
            handleClose(true)
        }
    }

    const handleAttachLink = (option:any) => {
        if (selectedEvent.slug.length>0) {
            setErrorMessage('Please select an event.');
          } else {
            setLinkInMessage(option)
            resetState()
            handleClose()
          }
    };

    const getEventDetails = async (slug:any) => {
        const response = await axios.get(`${GET_EVENT_DETAILS_API}/${slug}/`);
        if (response?.data) {
            setSelectedEventDetails(response.data)
        }
    }
    if(eventList){
        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <h3>Select Itinerary</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <Dropdown onSelect={(option:any, optionData:any) => {setSelectedEvent({"slug":option,"name":optionData.target.getAttribute('data-name')}); getEventDetails(option); setErrorMessage("");}}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="w-100">
                                    {selectedEvent.name?.length>1?selectedEvent.name: "Select Event"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="w-100">
                                    {eventList.map((data:any, idx:any) => {
                                        return <Dropdown.Item eventKey={data.slug} data-name={data.name} key={idx}>
                                            {data.name}
                                        </Dropdown.Item>
                                    })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            {errorMessage && 
                                <div className='mt-4 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{errorMessage}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className={`${hasSendItinerary?"col-lg-6":"col-lg-12"} col-sm-12 mt-4`}>
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" defaultChecked/>
                            <label className="btn border border-primary border-1 btn-outline-primary bg-hover-primary text-hover-white w-100" htmlFor="btnradio1" onClick={() => setIsTemplateFlow(false)}>Attach Link</label>
                        </div> */}
                        {hasSendItinerary ?
                            <div className="col-lg-12 col-sm-12 mt-4">
                                <input type="radio" className="btn-check" name="btnradio" id="btnradio2" />
                                <label className="btn border border-primary border-1 btn-outline-primary bg-hover-primary text-hover-white w-100" htmlFor="btnradio2" onClick={() => setIsTemplateFlow(true)}>Message Preview</label>
                            </div>
                        :
                            <div className='mt-4 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>Please Contact Logout Support to get the template Message Approved</div>
                            </div>
                        }
                    </div>
                    { hasSendItinerary && isTemplateFlow && selectedEventDetails && selectedEvent.slug.length>0 &&
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-custom card-flush mt-4">
                                    <div className="card-header">
                                        <h3 className="card-title">Message Preview</h3>
                                    </div>
                                    <div className="card-body py-5">
                                        <div className="square-image-container rounded-3">
                                            <img src={`${HOST_URL}${selectedEventDetails.picture}`} alt="Square Image" className="square-image" />
                                        </div>
                                        <pre>
{`
👉 *Event Name* : ${selectedEventDetails.name}
👉 *Price* : Starts from Rs ${selectedEventDetails.min_price}
👉 *Duration* : ${selectedEventDetails.duration==null?"NA":selectedEventDetails.duration }

⏰ Hurry Up! Last few slots remaining.
`}
                                        </pre>
                                        <a className="btn btn-light w-100 mt-5" href={`${HOST_URL}/tours/${selectedEvent.slug}/pdf/?download=1`}>
                                            <i className="bi bi-box-arrow-up-right"></i>
                                            Download Itenerary
                                        </a>
                                        <a className="btn btn-light w-100 mt-4" href={`${HOST_URL}/tours/${selectedEvent.slug}/booking/`}>
                                            <i className="bi bi-box-arrow-up-right"></i>
                                            Book Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {/* {isTemplateFlow?
                        <button className="btn btn-primary" onClick={() => sendTemplateMessage(selectedEventDetails)}>
                            {!loading && <span className='indicator-label'>Send Message</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    :<button className="btn btn-primary" onClick={() => handleAttachLink(selectedEvent)}>Attach Link</button>
                    } */}
                    {hasSendItinerary && <button className="btn btn-primary" onClick={() => sendTemplateMessage(selectedEventDetails)}>
                            {!loading && <span className='indicator-label'>Send Message</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                    </button>}
                </Modal.Footer>
            </Modal>
        )
    }
    return<></>
}

export {EventModal}